export const AtomsAdaptiveImage = () => import('../../components/atoms/AdaptiveImage/index.vue' /* webpackChunkName: "components/atoms-adaptive-image" */).then(c => wrapFunctional(c.default || c))
export const AtomsButton = () => import('../../components/atoms/Button/index.vue' /* webpackChunkName: "components/atoms-button" */).then(c => wrapFunctional(c.default || c))
export const AtomsLabelDisplay = () => import('../../components/atoms/LabelDisplay/index.vue' /* webpackChunkName: "components/atoms-label-display" */).then(c => wrapFunctional(c.default || c))
export const AtomsLoader = () => import('../../components/atoms/Loader/index.vue' /* webpackChunkName: "components/atoms-loader" */).then(c => wrapFunctional(c.default || c))
export const AtomsMarkDownDisplay = () => import('../../components/atoms/MarkDownDisplay/index.vue' /* webpackChunkName: "components/atoms-mark-down-display" */).then(c => wrapFunctional(c.default || c))
export const AtomsNotification = () => import('../../components/atoms/Notification/index.vue' /* webpackChunkName: "components/atoms-notification" */).then(c => wrapFunctional(c.default || c))
export const AtomsPercentageDisplay = () => import('../../components/atoms/PercentageDisplay/index.vue' /* webpackChunkName: "components/atoms-percentage-display" */).then(c => wrapFunctional(c.default || c))
export const AtomsPositionDisplay = () => import('../../components/atoms/PositionDisplay/index.vue' /* webpackChunkName: "components/atoms-position-display" */).then(c => wrapFunctional(c.default || c))
export const AtomsPriceDisplay = () => import('../../components/atoms/PriceDisplay/index.vue' /* webpackChunkName: "components/atoms-price-display" */).then(c => wrapFunctional(c.default || c))
export const AtomsTextField = () => import('../../components/atoms/TextField/index.vue' /* webpackChunkName: "components/atoms-text-field" */).then(c => wrapFunctional(c.default || c))
export const AtomsTimeElapsedDisplay = () => import('../../components/atoms/TimeElapsedDisplay/index.vue' /* webpackChunkName: "components/atoms-time-elapsed-display" */).then(c => wrapFunctional(c.default || c))
export const MoleculesAchievementBox = () => import('../../components/molecules/AchievementBox/index.vue' /* webpackChunkName: "components/molecules-achievement-box" */).then(c => wrapFunctional(c.default || c))
export const MoleculesAssetBox = () => import('../../components/molecules/AssetBox/index.vue' /* webpackChunkName: "components/molecules-asset-box" */).then(c => wrapFunctional(c.default || c))
export const MoleculesAssetRowLoader = () => import('../../components/molecules/AssetRowLoader/index.vue' /* webpackChunkName: "components/molecules-asset-row-loader" */).then(c => wrapFunctional(c.default || c))
export const MoleculesAssetTabSelector = () => import('../../components/molecules/AssetTabSelector/index.vue' /* webpackChunkName: "components/molecules-asset-tab-selector" */).then(c => wrapFunctional(c.default || c))
export const MoleculesBackButton = () => import('../../components/molecules/BackButton/index.vue' /* webpackChunkName: "components/molecules-back-button" */).then(c => wrapFunctional(c.default || c))
export const MoleculesBanner = () => import('../../components/molecules/Banner/index.vue' /* webpackChunkName: "components/molecules-banner" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCaptionImage = () => import('../../components/molecules/CaptionImage/index.vue' /* webpackChunkName: "components/molecules-caption-image" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCareerStatsSummary = () => import('../../components/molecules/CareerStatsSummary/index.vue' /* webpackChunkName: "components/molecules-career-stats-summary" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCategoryCard = () => import('../../components/molecules/CategoryCard/index.vue' /* webpackChunkName: "components/molecules-category-card" */).then(c => wrapFunctional(c.default || c))
export const MoleculesDocuments = () => import('../../components/molecules/Documents/index.vue' /* webpackChunkName: "components/molecules-documents" */).then(c => wrapFunctional(c.default || c))
export const MoleculesFooterLinks = () => import('../../components/molecules/FooterLinks/index.vue' /* webpackChunkName: "components/molecules-footer-links" */).then(c => wrapFunctional(c.default || c))
export const MoleculesHowItWorksModal = () => import('../../components/molecules/HowItWorksModal/index.vue' /* webpackChunkName: "components/molecules-how-it-works-modal" */).then(c => wrapFunctional(c.default || c))
export const MoleculesHtmlSection = () => import('../../components/molecules/HtmlSection/index.vue' /* webpackChunkName: "components/molecules-html-section" */).then(c => wrapFunctional(c.default || c))
export const MoleculesInstagramMedia = () => import('../../components/molecules/InstagramMedia/index.vue' /* webpackChunkName: "components/molecules-instagram-media" */).then(c => wrapFunctional(c.default || c))
export const MoleculesInvestSwitch = () => import('../../components/molecules/InvestSwitch/index.vue' /* webpackChunkName: "components/molecules-invest-switch" */).then(c => wrapFunctional(c.default || c))
export const MoleculesInvestorProfileInfo = () => import('../../components/molecules/InvestorProfileInfo/index.vue' /* webpackChunkName: "components/molecules-investor-profile-info" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMarkdownSection = () => import('../../components/molecules/MarkdownSection/index.vue' /* webpackChunkName: "components/molecules-markdown-section" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMenuButton = () => import('../../components/molecules/MenuButton/index.vue' /* webpackChunkName: "components/molecules-menu-button" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMicrositeLinks = () => import('../../components/molecules/MicrositeLinks/index.vue' /* webpackChunkName: "components/molecules-microsite-links" */).then(c => wrapFunctional(c.default || c))
export const MoleculesNFTEditionView = () => import('../../components/molecules/NFTEditionView/index.vue' /* webpackChunkName: "components/molecules-n-f-t-edition-view" */).then(c => wrapFunctional(c.default || c))
export const MoleculesNewsItem = () => import('../../components/molecules/NewsItem/index.vue' /* webpackChunkName: "components/molecules-news-item" */).then(c => wrapFunctional(c.default || c))
export const MoleculesSocialButtons = () => import('../../components/molecules/SocialButtons/index.vue' /* webpackChunkName: "components/molecules-social-buttons" */).then(c => wrapFunctional(c.default || c))
export const MoleculesTabSelector = () => import('../../components/molecules/TabSelector/index.vue' /* webpackChunkName: "components/molecules-tab-selector" */).then(c => wrapFunctional(c.default || c))
export const MoleculesTestimonyInfo = () => import('../../components/molecules/TestimonyInfo/index.vue' /* webpackChunkName: "components/molecules-testimony-info" */).then(c => wrapFunctional(c.default || c))
export const MoleculesTestimonyItem = () => import('../../components/molecules/TestimonyItem/index.vue' /* webpackChunkName: "components/molecules-testimony-item" */).then(c => wrapFunctional(c.default || c))
export const MoleculesThoroughbredBox = () => import('../../components/molecules/ThoroughbredBox/index.vue' /* webpackChunkName: "components/molecules-thoroughbred-box" */).then(c => wrapFunctional(c.default || c))
export const MoleculesThreeDCarousel = () => import('../../components/molecules/ThreeDCarousel/index.vue' /* webpackChunkName: "components/molecules-three-d-carousel" */).then(c => wrapFunctional(c.default || c))
export const MoleculesTitleSubTitle = () => import('../../components/molecules/TitleSubTitle/index.vue' /* webpackChunkName: "components/molecules-title-sub-title" */).then(c => wrapFunctional(c.default || c))
export const MoleculesWefunderBanner = () => import('../../components/molecules/WefunderBanner/index.vue' /* webpackChunkName: "components/molecules-wefunder-banner" */).then(c => wrapFunctional(c.default || c))
export const OrganismsAssetExplorerMock = () => import('../../components/organisms/AssetExplorer/assetExplorer.mock.js' /* webpackChunkName: "components/organisms-asset-explorer-mock" */).then(c => wrapFunctional(c.default || c))
export const OrganismsAssetExplorer = () => import('../../components/organisms/AssetExplorer/index.vue' /* webpackChunkName: "components/organisms-asset-explorer" */).then(c => wrapFunctional(c.default || c))
export const OrganismsBackgroundMediaSection = () => import('../../components/organisms/BackgroundMediaSection/index.vue' /* webpackChunkName: "components/organisms-background-media-section" */).then(c => wrapFunctional(c.default || c))
export const OrganismsBiographyMock = () => import('../../components/organisms/Biography/biography.mock.js' /* webpackChunkName: "components/organisms-biography-mock" */).then(c => wrapFunctional(c.default || c))
export const OrganismsBiography = () => import('../../components/organisms/Biography/index.vue' /* webpackChunkName: "components/organisms-biography" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCareerStats = () => import('../../components/organisms/CareerStats/index.vue' /* webpackChunkName: "components/organisms-career-stats" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCareerStatsViewModel = () => import('../../components/organisms/CareerStats/view-model.js' /* webpackChunkName: "components/organisms-career-stats-view-model" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCarousel = () => import('../../components/organisms/Carousel/index.vue' /* webpackChunkName: "components/organisms-carousel" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCategoryBackgroundMediaSection = () => import('../../components/organisms/CategoryBackgroundMediaSection/index.vue' /* webpackChunkName: "components/organisms-category-background-media-section" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCategoryFAQSection = () => import('../../components/organisms/CategoryFAQSection/index.vue' /* webpackChunkName: "components/organisms-category-f-a-q-section" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCategoryInvestmentsSection = () => import('../../components/organisms/CategoryInvestmentsSection/index.vue' /* webpackChunkName: "components/organisms-category-investments-section" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCategoryOfferingsSection = () => import('../../components/organisms/CategoryOfferingsSection/index.vue' /* webpackChunkName: "components/organisms-category-offerings-section" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCategoryPublicationLogos = () => import('../../components/organisms/CategoryPublicationLogos/index.vue' /* webpackChunkName: "components/organisms-category-publication-logos" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCategoryTestimonialsSection = () => import('../../components/organisms/CategoryTestimonialsSection/index.vue' /* webpackChunkName: "components/organisms-category-testimonials-section" */).then(c => wrapFunctional(c.default || c))
export const OrganismsFAQSection = () => import('../../components/organisms/FAQSection/index.vue' /* webpackChunkName: "components/organisms-f-a-q-section" */).then(c => wrapFunctional(c.default || c))
export const OrganismsHeroVideo = () => import('../../components/organisms/HeroVideo/index.vue' /* webpackChunkName: "components/organisms-hero-video" */).then(c => wrapFunctional(c.default || c))
export const OrganismsHighlights = () => import('../../components/organisms/Highlights/index.vue' /* webpackChunkName: "components/organisms-highlights" */).then(c => wrapFunctional(c.default || c))
export const OrganismsHorizontalList = () => import('../../components/organisms/HorizontalList/index.vue' /* webpackChunkName: "components/organisms-horizontal-list" */).then(c => wrapFunctional(c.default || c))
export const OrganismsHowItWorks = () => import('../../components/organisms/HowItWorks/index.vue' /* webpackChunkName: "components/organisms-how-it-works" */).then(c => wrapFunctional(c.default || c))
export const OrganismsInvestFloat = () => import('../../components/organisms/InvestFloat/index.vue' /* webpackChunkName: "components/organisms-invest-float" */).then(c => wrapFunctional(c.default || c))
export const OrganismsInvestmentsSection = () => import('../../components/organisms/InvestmentsSection/index.vue' /* webpackChunkName: "components/organisms-investments-section" */).then(c => wrapFunctional(c.default || c))
export const OrganismsMicrositeHeader = () => import('../../components/organisms/MicrositeHeader/index.vue' /* webpackChunkName: "components/organisms-microsite-header" */).then(c => wrapFunctional(c.default || c))
export const OrganismsMicrositeHeaderMock = () => import('../../components/organisms/MicrositeHeader/micrositeHeader.mock.js' /* webpackChunkName: "components/organisms-microsite-header-mock" */).then(c => wrapFunctional(c.default || c))
export const OrganismsMobileMenu = () => import('../../components/organisms/MobileMenu/index.vue' /* webpackChunkName: "components/organisms-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const OrganismsNFTCollection = () => import('../../components/organisms/NFTCollection/index.vue' /* webpackChunkName: "components/organisms-n-f-t-collection" */).then(c => wrapFunctional(c.default || c))
export const OrganismsNews = () => import('../../components/organisms/News/index.vue' /* webpackChunkName: "components/organisms-news" */).then(c => wrapFunctional(c.default || c))
export const OrganismsNewsletter = () => import('../../components/organisms/Newsletter/index.vue' /* webpackChunkName: "components/organisms-newsletter" */).then(c => wrapFunctional(c.default || c))
export const OrganismsOfferingsSection = () => import('../../components/organisms/OfferingsSection/index.vue' /* webpackChunkName: "components/organisms-offerings-section" */).then(c => wrapFunctional(c.default || c))
export const OrganismsPortfolioHeader = () => import('../../components/organisms/PortfolioHeader/index.vue' /* webpackChunkName: "components/organisms-portfolio-header" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProviderInfo = () => import('../../components/organisms/ProviderInfo/index.vue' /* webpackChunkName: "components/organisms-provider-info" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProviderInfoMock = () => import('../../components/organisms/ProviderInfo/providerInfo.mock.js' /* webpackChunkName: "components/organisms-provider-info-mock" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProviderInfoVmTransform = () => import('../../components/organisms/ProviderInfo/vm-transform.js' /* webpackChunkName: "components/organisms-provider-info-vm-transform" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProviderNFT = () => import('../../components/organisms/ProviderNFT/index.vue' /* webpackChunkName: "components/organisms-provider-n-f-t" */).then(c => wrapFunctional(c.default || c))
export const OrganismsPublicationLogos = () => import('../../components/organisms/PublicationLogos/index.vue' /* webpackChunkName: "components/organisms-publication-logos" */).then(c => wrapFunctional(c.default || c))
export const OrganismsQuotesSection = () => import('../../components/organisms/QuotesSection/index.vue' /* webpackChunkName: "components/organisms-quotes-section" */).then(c => wrapFunctional(c.default || c))
export const OrganismsSblxDescription = () => import('../../components/organisms/SblxDescription/index.vue' /* webpackChunkName: "components/organisms-sblx-description" */).then(c => wrapFunctional(c.default || c))
export const OrganismsSectionList = () => import('../../components/organisms/SectionList/index.vue' /* webpackChunkName: "components/organisms-section-list" */).then(c => wrapFunctional(c.default || c))
export const OrganismsSiteFooter = () => import('../../components/organisms/SiteFooter/index.vue' /* webpackChunkName: "components/organisms-site-footer" */).then(c => wrapFunctional(c.default || c))
export const OrganismsSliderGroup = () => import('../../components/organisms/SliderGroup/index.vue' /* webpackChunkName: "components/organisms-slider-group" */).then(c => wrapFunctional(c.default || c))
export const OrganismsSportblxOfferingsSection = () => import('../../components/organisms/SportblxOfferingsSection/index.vue' /* webpackChunkName: "components/organisms-sportblx-offerings-section" */).then(c => wrapFunctional(c.default || c))
export const OrganismsTestimonials = () => import('../../components/organisms/Testimonials/index.vue' /* webpackChunkName: "components/organisms-testimonials" */).then(c => wrapFunctional(c.default || c))
export const OrganismsTestimonialsSection = () => import('../../components/organisms/TestimonialsSection/index.vue' /* webpackChunkName: "components/organisms-testimonials-section" */).then(c => wrapFunctional(c.default || c))
export const OrganismsTopNavbar = () => import('../../components/organisms/TopNavbar/index.vue' /* webpackChunkName: "components/organisms-top-navbar" */).then(c => wrapFunctional(c.default || c))
export const OrganismsTopNavigation = () => import('../../components/organisms/TopNavigation/index.vue' /* webpackChunkName: "components/organisms-top-navigation" */).then(c => wrapFunctional(c.default || c))
export const OrganismsVideoSelector = () => import('../../components/organisms/VideoSelector/index.vue' /* webpackChunkName: "components/organisms-video-selector" */).then(c => wrapFunctional(c.default || c))
export const TemplatesCareersPage = () => import('../../components/templates/CareersPage/index.vue' /* webpackChunkName: "components/templates-careers-page" */).then(c => wrapFunctional(c.default || c))
export const TemplatesCategory = () => import('../../components/templates/Category/index.vue' /* webpackChunkName: "components/templates-category" */).then(c => wrapFunctional(c.default || c))
export const TemplatesCategoryPlacePage = () => import('../../components/templates/CategoryPlacePage/index.vue' /* webpackChunkName: "components/templates-category-place-page" */).then(c => wrapFunctional(c.default || c))
export const TemplatesCorporationMicrosite = () => import('../../components/templates/CorporationMicrosite/index.vue' /* webpackChunkName: "components/templates-corporation-microsite" */).then(c => wrapFunctional(c.default || c))
export const TemplatesError = () => import('../../components/templates/Error/index.vue' /* webpackChunkName: "components/templates-error" */).then(c => wrapFunctional(c.default || c))
export const TemplatesInfoPage = () => import('../../components/templates/InfoPage/index.vue' /* webpackChunkName: "components/templates-info-page" */).then(c => wrapFunctional(c.default || c))
export const TemplatesJobPosting = () => import('../../components/templates/JobPosting/index.vue' /* webpackChunkName: "components/templates-job-posting" */).then(c => wrapFunctional(c.default || c))
export const TemplatesMaintenance = () => import('../../components/templates/Maintenance/index.vue' /* webpackChunkName: "components/templates-maintenance" */).then(c => wrapFunctional(c.default || c))
export const TemplatesMarkdownPage = () => import('../../components/templates/MarkdownPage/index.vue' /* webpackChunkName: "components/templates-markdown-page" */).then(c => wrapFunctional(c.default || c))
export const TemplatesNewHomePage = () => import('../../components/templates/NewHomePage/index.vue' /* webpackChunkName: "components/templates-new-home-page" */).then(c => wrapFunctional(c.default || c))
export const TemplatesOffering = () => import('../../components/templates/Offering/index.vue' /* webpackChunkName: "components/templates-offering" */).then(c => wrapFunctional(c.default || c))
export const TemplatesOfferings = () => import('../../components/templates/Offerings/index.vue' /* webpackChunkName: "components/templates-offerings" */).then(c => wrapFunctional(c.default || c))
export const TemplatesSportblxPlacePage = () => import('../../components/templates/SportblxPlacePage/index.vue' /* webpackChunkName: "components/templates-sportblx-place-page" */).then(c => wrapFunctional(c.default || c))
export const TemplatesSubCategory = () => import('../../components/templates/SubCategory/index.vue' /* webpackChunkName: "components/templates-sub-category" */).then(c => wrapFunctional(c.default || c))
export const TemplatesThoroughbredPortfolio = () => import('../../components/templates/ThoroughbredPortfolio/index.vue' /* webpackChunkName: "components/templates-thoroughbred-portfolio" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCareerStatsViewModels = () => import('../../components/organisms/CareerStats/viewModels/index.js' /* webpackChunkName: "components/organisms-career-stats-view-models" */).then(c => wrapFunctional(c.default || c))
export const OrganismsInvestmentsSectionComponentsCustomInvestments = () => import('../../components/organisms/InvestmentsSection/components/CustomInvestments/index.vue' /* webpackChunkName: "components/organisms-investments-section-components-custom-investments" */).then(c => wrapFunctional(c.default || c))
export const OrganismsInvestmentsSectionComponentsIndustryRecords = () => import('../../components/organisms/InvestmentsSection/components/IndustryRecords/index.vue' /* webpackChunkName: "components/organisms-investments-section-components-industry-records" */).then(c => wrapFunctional(c.default || c))
export const OrganismsInvestmentsSectionComponentsInvestingForAll = () => import('../../components/organisms/InvestmentsSection/components/InvestingForAll/index.vue' /* webpackChunkName: "components/organisms-investments-section-components-investing-for-all" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
