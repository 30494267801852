import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_4b84322a from 'nuxt_plugin_plugin_4b84322a' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_plugin_7215bce3 from 'nuxt_plugin_plugin_7215bce3' // Source: ./vuetify/plugin.js (mode: 'all')
import nuxt_plugin_image_639c5108 from 'nuxt_plugin_image_639c5108' // Source: ./image.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_10c95829 from 'nuxt_plugin_cookieuniversalnuxt_10c95829' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_axios_39aa4afe from 'nuxt_plugin_axios_39aa4afe' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_googleanalytics_4c96d9f6 from 'nuxt_plugin_googleanalytics_4c96d9f6' // Source: ./google-analytics.js (mode: 'client')
import nuxt_plugin_notifications_36de4932 from 'nuxt_plugin_notifications_36de4932' // Source: ../plugins/notifications (mode: 'all')
import nuxt_plugin_persistedstate_1f6ae7f4 from 'nuxt_plugin_persistedstate_1f6ae7f4' // Source: ../plugins/persistedstate (mode: 'all')
import nuxt_plugin_breadcrumb_675a200e from 'nuxt_plugin_breadcrumb_675a200e' // Source: ../plugins/breadcrumb (mode: 'all')
import nuxt_plugin_hotjar_607e9cb8 from 'nuxt_plugin_hotjar_607e9cb8' // Source: ../plugins/hotjar (mode: 'client')
import nuxt_plugin_fontawesome_b8d42804 from 'nuxt_plugin_fontawesome_b8d42804' // Source: ../plugins/fontawesome (mode: 'all')
import nuxt_plugin_previewclient_5fee6a20 from 'nuxt_plugin_previewclient_5fee6a20' // Source: ../plugins/preview.client.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"htmlAttrs":{"lang":"en"},"titleTemplate":"%s","title":"marketing-site","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"title","name":"title","content":"Annestes Farm"},{"hid":"description","name":"description","content":"Welcome to Annestes Farm"},{"hid":"ogdescription","name":"og:description","content":"Welcome to Annestes Farm"},{"hid":"ogimage","property":"og:image","content":"https:\u002F\u002Fproductionx-strapi-assets.s3.us-east-2.amazonaws.com\u002FAnnestes_Farm_logos_white_84d7d9d1d6.png"},{"hid":"ogtype","property":"og:type","content":"website"},{"hid":"ogtitle","property":"og:title","content":"Annestes Farm"},{"hid":"fbapp_id","property":"fb:app_id","content":"507471660001376"},{"hid":"ogsitename","property":"og:site_name","content":"Annestes Farm"},{"name":"facebook-domain-verification","content":"9x1n85bolvlxbf5kcl15zrucrvwzll"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"stylesheet","type":"text\u002Fcss","href":"https:\u002F\u002Fcdn.jsdelivr.net\u002Fnpm\u002F@mdi\u002Ffont@latest\u002Fcss\u002Fmaterialdesignicons.min.css"}],"script":[],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_4b84322a === 'function') {
    await nuxt_plugin_plugin_4b84322a(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_7215bce3 === 'function') {
    await nuxt_plugin_plugin_7215bce3(app.context, inject)
  }

  if (typeof nuxt_plugin_image_639c5108 === 'function') {
    await nuxt_plugin_image_639c5108(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_10c95829 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_10c95829(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_39aa4afe === 'function') {
    await nuxt_plugin_axios_39aa4afe(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleanalytics_4c96d9f6 === 'function') {
    await nuxt_plugin_googleanalytics_4c96d9f6(app.context, inject)
  }

  if (typeof nuxt_plugin_notifications_36de4932 === 'function') {
    await nuxt_plugin_notifications_36de4932(app.context, inject)
  }

  if (typeof nuxt_plugin_persistedstate_1f6ae7f4 === 'function') {
    await nuxt_plugin_persistedstate_1f6ae7f4(app.context, inject)
  }

  if (typeof nuxt_plugin_breadcrumb_675a200e === 'function') {
    await nuxt_plugin_breadcrumb_675a200e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_hotjar_607e9cb8 === 'function') {
    await nuxt_plugin_hotjar_607e9cb8(app.context, inject)
  }

  if (typeof nuxt_plugin_fontawesome_b8d42804 === 'function') {
    await nuxt_plugin_fontawesome_b8d42804(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_previewclient_5fee6a20 === 'function') {
    await nuxt_plugin_previewclient_5fee6a20(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
